import React from "react";
import "./QRModal.css";
import qrImage from '../assets/qr.png'; // Adjust path accordingly
import cross from '../assets/cross.png';
const QRModal = ({ showModal, handleClose }) => {
  if (!showModal) {
    return null; // If showModal is false, don't render anything
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <img
          src={cross} 
          alt="Close"
          className="close-btn"
          onClick={handleClose}
        />
        <h2>Scan The QR Code</h2>
        <img src={qrImage} alt="QR Code" className="qr-code" />
      </div>
    </div>
  );
};

export default QRModal;
