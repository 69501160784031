// src/pages/DGeDelivery.js
import React from 'react';

const DGeDelivery = () => {
  return (
    <div>
      <h1>DG eDelivery Services</h1>
      <p>Details about DG eDelivery services will go here.</p>
    </div>
  );
};

export default DGeDelivery;
